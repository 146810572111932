
























import {Origen,GuardarOrigen,EditarOrigen} from '@/entidades/Maestro/Ktb/Origen';
import ResponseGenerico from '@/entidades/ResponseGenerico';
import Alerta from '@/entidades/Sistema/Alerta';
import Loading from '@/entidades/Sistema/Loading';
import {Vue,Component,Prop} from 'vue-property-decorator'
import {Validate, Validations} from 'vuelidate-property-decorators';
import {required} from 'vuelidate/lib/validators'
import { Action } from 'vuex-class';
@Component
export default class FormularioAddTarifa extends Vue
{

  @Action('changeLoading',{namespace:'sistemaModule'}) changeLoading!:Function;
  @Action('changeAlerta',{namespace:'sistemaModule'}) changeAlerta!:Function;
  @Prop({required:true,type:Number})
  action!:number;
  @Prop({required:false})
  modelo!:Origen;

  descripcion?:string="";
  origenId?:number =0;

  //Configuracion de validacion
  @Validations()
     validations = {
         descripcion : {required},
     }

 async guardar()
 {
     try
     {
         this.changeLoading(new Loading(true,"Registrando informacion"));
         let response = new ResponseGenerico(false,"");
         let origen:Origen = new Origen(this.origenId,this.descripcion,true);
         response = this.action == 1 ?  await GuardarOrigen(origen) : await EditarOrigen(origen);
         if(response.isSuccess == true)
         {
             this.$emit('limpiar');
         }
         else
         {
            this.changeAlerta(new Alerta(response.isMessage,true,"danger",3000));
         }
     }
     catch(error)
     {
         //@ts-ignore
        this.changeAlerta(new Alerta(error,true,"danger",3000));
     }
     finally
     {
        this.changeLoading(new Loading(false,""));
     }
    
 }


 get mensaje()
 {
     if(this.action == 1)
        return {'titulo' : "REGISTRO DE ORIGEN" , 'boton' : "GUARDAR"};
    else
        return {'titulo' : "EDICION DE ORIGEN" , 'boton' : "EDITAR"};
 }

 mounted() {
    if(this.modelo!=undefined)
    {
        this.descripcion = this.modelo.descripcion;
        this.origenId = this.modelo.origenId;
    }   
 }

}
